<template>
  <v-container class="pa-4 text-center">
    <v-row class="text-left">
      <v-col
      cols="12"
      md="12">
        <v-alert
          color="light-green darken-1"
          dark
          icon="mdi-information"
          border="left"
          prominent
        >
          TDA is digitilizing its operations using the following ERP systems.
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      class="fill-height"
      justify="center"
    >
      <template >
        <v-col v-for="(item, i) in items"
          :key="i"
          cols="12"
          md="3"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              color="light-green darken-4"
              :href="item.to" 
            >
              <v-img                
                height="225px"  
                :to="item.to"            
              >
                <v-card-title class="text-h6 white--text">
                  <v-row
                    class="fill-height flex-column"
                    justify="space-between"                    
                  >
                    <p class="mt-4 subheading text-left">
                      <v-icon class="white--text">mdi-check-circle</v-icon>
                      {{ item.title }}
                    </p>

                    <div>
                      <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                        {{ item.text }}
                      </p>
                      <p class="text-caption font-weight-medium font-italic text-left">
                        {{ item.subtext }}
                      </p>
                    </div>

                    <div class="align-self-center">
                      <v-btn
                        v-for="(icon, index) in icons"
                        :key="index"
                        :class="{ 'show-btns': hover }"
                        :prepend-icon="icon"
                        :color="transparent"
                      > 
                        <v-icon
                          :class="{ 'show-btns': hover }"
                          
                        >
                          {{ icon }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col
      cols="12"
      md="8"
      offset="2">
        <v-alert
          border="bottom"
          colored-border
          type="info"
          elevation="2"
        >
          We will release Membership Management System soon. 
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
    data: () => ({
      icons: ['mdi-login'],
      // icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      items: [
        {
          title: 'TDA HRM',
          text: `Human Resource Management`,
          subtext: 'Recruiting professionals.',
          img: 'https://img.freepik.com/premium-vector/hrm-human-resource-management-icon-label-badge-vector-stock-illustration_100456-10644.jpg?w=2000',
          to: 'http://196.190.41.81:8080/login',
        },
        {
          title: 'TDA SCM',
          text: 'Supply Chain Management',
          subtext: 'Monitoring supply channels.',
          img: 'https://cdn.vuetifyjs.com/docs/images/cards/singer.jpg',
          to: 'http://196.190.41.81:8082/login',
        },
        {
          title: 'TDA PMS',
          text: 'Project Management System',
          subtext: 'Tracking project implementation.',
          img: 'https://cdn.vuetifyjs.com/docs/images/cards/concert.jpg',
          to: 'http://tda-int.net/pms',
        },
        {
          title: 'TDA MMS',
          text: 'Membership Management System',
          subtext: 'Moblizing resource from members.',
          img: 'http://cdn.vuetifyjs.com/docs/images/cards/concert.jpg',
          to: '#',
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }),
  }
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.8;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>