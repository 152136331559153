import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PNF from '@/views/PNF.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      middleware: 'guest',
      title: 'Home'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: PNF,
    meta: {
      middleware: 'guest',
      title: 'Home'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      middleware: 'guest',
      title: 'About'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " - TDA ERP Systems";
  if (to.meta.middleware == 'guest') {
    next();
  } else {
    next();
  }
})
export default router
