<template>
  <v-container class="pa-4 text-center">
    <v-row class="text-left">
      <v-col
      cols="12"
      md="12">
        <v-alert
          color="light-green darken-1"
          dark
          icon="mdi-information"
          border="left"
          prominent
        >
          <h1>404</h1>
          Page Not Found.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PNF',
    data: () => ({
    
    }),
  }
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>